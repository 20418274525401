import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme';
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Select, Snackbar } from '@mui/material';
import api from '../services/api';
import { MenuItem } from '@mui/material';



export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const [empreas, setEmpresas] = React.useState()

  const item = {
    cod_empresa: 1000,
    cod_ppi: '',
    action: '',
    group: '',
    description: ''

  }

  const handleChangeCheck = (event) => {
    setState({
      ...state,
      [event.target.name]:  event.target.checked
    });
  };

  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };


  React.useEffect(()=>{
    getEditInfo()
    getGroups()
    getEmpresas()
  },[props.id, handlerSnack])

  const getEmpresas = ()=>{
    api.get('/empresa/all').then(res=>{
      setEmpresas(res.data)
    }).catch(err=>console.log(err))
  }
  const getEditInfo = ()=>{
    setState(item)
    if(!props.id) return
    api.get(`ppi/ppi/find/${props.id}`)
    .then(res=>{
      let data = res.data.__data__
      
      setState(data)
  

    }).catch(err=> setState(item))
  }
  const showNotify = (msg,sev="success")=>{
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }
  
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')
  const [ groups, setGroups ] = React.useState('')
  

  const getGroups = ()=>{
    api.get('/ppi/group/all').then(res=>{
      setGroups(res.data)
    }).catch(err=>console.log(err))
  }
  const handleSubmit = (event)=>{
    const handlerClose = ()=>{
      props.handlerClose()
    }
    event.preventDefault()
    if(!props.id){
      api.post('ppi/ppi/create', state)
      .then(()=>{
          showNotify("Registro adicionado com sucesso.")
          handlerClose()
      }).catch(()=>{
          showNotify("Um erro ocorreu...",'error')
      })
    }else{
      api.put(`ppi/ppi/edit/${props.id}/`, state)
      .then(()=>{
        showNotify("Registro editado com sucesso.")
        handlerClose()
    }).catch(()=>{
        handlerClose()
        showNotify("Um erro ocorreu...",'error')
    })
    }
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={()=>setHandlerSnack(false)}/>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='lg'>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
        <DialogTitle>
            {!props.id ? 'Criar PPI' : "Editar PPI"}
        </DialogTitle>
        <DialogContent>
        <TextField
          required
          size="small"
            margin="dense"
            id="description"
            name="description"
            label="Descrição"
            type="text"
            fullWidth
           value={state.description}
           onChange={handleChange}
            variant="outlined"
            inputProps={{
              maxLength:16777215
            }}
            />
        <FormControl fullWidth>
        <Select
        required
        size="small"
      labelId="Grupo"
        id="group"
        name='group'
        value={state.group}
        label="Grupo"
        onChange={handleChange}
  >

        {groups? groups.map((group,i)=>
          <MenuItem key={group.id} value={group.id}>{group.description}</MenuItem>
          ): ''}
  </Select>
  <Select
        required
        size="small"
      labelId="cod_empresa"
        id="cod_empresa"
        name='cod_empresa'
        value={state.cod_empresa}
        label="Empresa"
        onChange={handleChange}
  >

        {empreas? empreas.map((empresa,i)=>
          <MenuItem key={empresa.id} value={empresa.id}>{empresa.cod_empresa}- {empresa?.nome_fantasia}</MenuItem>
          ): ''}
  </Select>
       
  </FormControl>
          
          <TextField
          required
          size="small"
            margin="dense"
            id="cod_ppi"
            name="cod_ppi"
            label="PPI"
            type="number"
            fullWidth
           value={state.cod_ppi}
           onChange={handleChange}
            variant="outlined"
            />
             <TextField
             multiline
             rows={20}
          required
          size="small"
            margin="dense"
            id="action"
            name="action"
            label="Ação"
            type="text"
            fullWidth
           value={state.action}
           onChange={handleChange}
            variant="outlined"
            />
            <FormGroup>


  </FormGroup>

        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={props.handlerClose}>Cancelar</Button>
          <Button sx={{color: theme.palette.text.primary}} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
        </DialogActions>
          </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}